import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import authService from '../services/authService';
import { Container, Form, Button, Alert, Nav, Navbar } from 'react-bootstrap';
import { FaBackspace } from 'react-icons/fa';
import '../assets/css/Login.css'; 

const Signup = () => {
    const [formData, setFormData] = useState({
        name: '',
        pin: ''
    });
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!location.state || !location.state.fromAdmin) {
            navigate('/login');
        }
    }, [location, navigate]);

    const { name, pin } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleButtonClick = (value) => {
        if (pin.length < 4) {
            setFormData({ ...formData, pin: pin + value });
        }
    };

    const handleDelete = () => {
        setFormData({ ...formData, pin: '' });
    };

    const onSubmit = async e => {
        e.preventDefault();

        try {
            await authService.register(name, pin);
            navigate('/login');
        } catch (err) {
            console.error('Signup error:', err.response.data);
            setError(err.response.data.message);
        }
    };

    return (
        <div className="signup-page">
            <Container className="login-container">
                <img
                    alt=""
                    src={require('../assets/images/logo-text.png')}
                    width="auto"
                    height="100"
                    className="d-inline-block align-top"
                />
                <h2 className="mt-5">Register New User</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="name" className="mt-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="name" 
                            value={name} 
                            onChange={onChange} 
                            required 
                            className="name-input"
                        />
                    </Form.Group>
                    <Form.Group controlId="pin" className="mt-3">
                        <Form.Label>PIN</Form.Label>
                        <div className="pin-display">
                            {[...Array(4)].map((_, i) => (
                                <div key={i} className={`pin-box ${!pin[i] ? 'empty' : ''}`}>
                                    {pin[i] ? pin[i] : ''}
                                </div>
                            ))}
                        </div>
                    </Form.Group>
                    <div className="keypad mt-4">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0].map((value, i) => (
                            <Button
                                key={i}
                                variant="light"
                                className={`keypad-button${value === null ? ' invisible' : ''}`}
                                onClick={() => value !== null && handleButtonClick(value)}
                            >
                                {value}
                            </Button>
                        ))}
                        <Button
                            variant="light"
                            className="keypad-button"
                            onClick={handleDelete}
                        >
                            <FaBackspace />
                        </Button>
                    </div>
                    <div className="mt-4">
                        <Navbar fixed="bottom" className="keypad-footer">
                            <Nav className="mr-auto">
                                <Nav.Link onClick={() => navigate('/admin')}>Back to Admin</Nav.Link>
                            </Nav>
                            <Nav>
                            </Nav>
                            <Nav>
                                <Nav.Link onClick={onSubmit}>Signup</Nav.Link>
                            </Nav>
                        </Navbar>
                    </div>
                </Form>
            </Container>
        </div>
    );
};

export default Signup;
