import axios from 'axios';

const API_URL = '/api/leaderboard/';

const getRewards = () => {
    return axios.get(API_URL + 'rewards');
};

const rewardService = {
    getRewards
};

export default rewardService;
