import axios from 'axios';

const API_URL = '/api/auth/';

const register = (name, pin) => {
    return axios.post(API_URL + 'register', { name, pin });
};

const login = async (pin) => {
    const response = await axios.post(API_URL + 'login', { pin });
    console.log('Received login response:', response.data);

    if (response.data.token) {
        const tokenPayload = JSON.parse(atob(response.data.token.split('.')[1]));
        localStorage.setItem('userId', tokenPayload.user.id);
        localStorage.setItem('token', response.data.token); 
    }

    return response.data;
};

const verifyUserId = async () => {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    if (!userId || !token) return { valid: false };

    try {
        const response = await axios.post(API_URL + 'verify', { userId }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (err) {
        console.error('Error verifying user ID:', err);
        logout();
        throw err;
    }
};

const logout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
};

const authService = {
    register,
    login,
    verifyUserId,
    logout
};

export default authService;
