import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, FloatingLabel } from 'react-bootstrap';
import { MdPostAdd } from "react-icons/md";
import { FaTrash, FaRegKeyboard, FaUser } from 'react-icons/fa6';
import { FaTasks, FaCalendarAlt } from 'react-icons/fa';

const ChoreForm = ({ onChoreSubmitted, initialChore, users, choreStatusOptions, closeModal, onChoreDeleted }) => {
    const [description, setDescription] = useState(initialChore ? initialChore.description : '');
    const [points, setPoints] = useState(initialChore ? initialChore.points : 0);
    const [rejectionExplanation, setRejectionExplanation] = useState(initialChore && initialChore.status === 'rejected' ? initialChore.rejectionExplanation : '');
    const [dueDay, setDueDay] = useState(initialChore ? initialChore.dueDay : '');
    const [assignedTo, setAssignedTo] = useState(initialChore && initialChore.assignedTo ? initialChore.assignedTo._id : null);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState(initialChore ? initialChore.status : '');

    useEffect(() => {
        if (initialChore) {
            setDescription(initialChore.description);
            setPoints(initialChore.points);
            if (initialChore.status === 'rejected') {
                setRejectionExplanation(initialChore.rejectionExplanation);
            }
        }
    }, [initialChore]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Validate required fields
            if (!description) {
                setError('Description is required.');
                return;
            }
    
            const choreData = {
                description,
                points,
                assignedTo: assignedTo, // This will be null if "Unassigned" is selected
                status: status, // This is now updated based on the dropdown change
                rejectionExplanation: initialChore && initialChore.status === 'rejected' ? rejectionExplanation : null,
                dueDay: dueDay === '' ? 'Any' : dueDay,
            };
    
            console.log('Chore Form: Chore data being submitted:', choreData); 
    
            await onChoreSubmitted(choreData);
    
            // Emit a WebSocket event if a new chore is added
            if (!initialChore) {
                const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');
                ws.onopen = () => {
                    ws.send(JSON.stringify({ type: 'NEW_CHORE_ADDED' }));
                    ws.close();
                };
            }
    
            closeModal();
        } catch (err) {
            console.error('Error submitting chore:', err);
            setError('Failed to submit chore');
        }
    };
    

    const handleDelete = async () => {
        try {
            await onChoreDeleted(initialChore._id);
            closeModal();
            // Send a WebSocket message to notify clients that a chore has been deleted
            const ws = new WebSocket(process.env.NODE_ENV === 'production' ? 'wss://www.chorehero.cloud' : 'ws://localhost:5000');
            ws.onopen = () => {
                ws.send(JSON.stringify({ type: 'CHORE_DELETED'}));
                ws.close();
            };
        } catch (err) {
            console.error(err);
            setError('Failed to delete chore');
        }
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                {error && <Alert variant="danger">{error}</Alert>}
                <Row className="d-flex align-items-center mb-3">
                    <Col xs={1}>
                        <FaRegKeyboard />
                    </Col>
                    <Col>         
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="Chore Description"
                            className="mb-1"
                        >
                            <Form.Control
                                as="textarea"
                                value={description}
                                placeholder="Chore Description"
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row className="d-flex align-items-center mb-3">
                    <Col xs={3}>
                        <Form.Label><strong>Points:</strong> {points}</Form.Label>
                    </Col>
                    <Col>  
                        <Form.Range
                            value={points}
                            onChange={(e) => setPoints(Number(e.target.value))}
                            min={0}
                            max={10}
                        />
                    </Col>
                </Row>                
                <Row className="d-flex align-items-center mb-3">
                    <Col xs={1}>
                        <FaCalendarAlt />
                    </Col>
                    <Col>
                    <Form.Group controlId="dueDay">     
                        <FloatingLabel
                            controlId="floatingSelect"
                            label="Start Day Restraint"
                            className="mb-1"
                        >
                            <Form.Control as="select" value={dueDay} onChange={(e) => setDueDay(e.target.value)}>
                                <option value="Any">No Restraint</option>
                                <option value="Monday">Monday</option>
                                <option value="Tuesday">Tuesday</option>
                                <option value="Wednesday">Wednesday</option>
                                <option value="Thursday">Thursday</option>
                                <option value="Friday">Friday</option>
                            </Form.Control>
                        </FloatingLabel>
                    </Form.Group>
                    </Col>
                </Row>
                {initialChore && (
                    <>
                        <Row className="d-flex align-items-center mb-3">
                            <Col xs={1}>
                                <FaUser />
                            </Col>
                            <Col>      
                                <FloatingLabel
                                    controlId="floatingSelect"
                                    label="Assign To"
                                    className="mb-1"
                                >
                                    <Form.Control
                                        as="select"
                                        value={assignedTo} // Use the state variable assignedTo
                                        onChange={(e) => {
                                            const selectedValue = e.target.value === '' ? null : e.target.value;
                                            setAssignedTo(selectedValue);

                                            // If the selected option is "Unassigned", set the status to "New"
                                            if (selectedValue === null) {
                                                setStatus('new');
                                            } else if (status === 'new') {
                                                // If the selected option is a user and the current status is "New", set the status to "Pending"
                                                setStatus('pending');
                                            }
                                        }}
                                    >
                                        <option value=''>Unassigned</option>
                                        {users.map((user) => (
                                            <option key={user._id} value={user._id}>
                                                {user.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>
                            <Col xs={1}>
                                <FaTasks />
                            </Col>
                            <Col>      
                                <FloatingLabel
                                    controlId="floatingSelect"
                                    label="Status"
                                    className="mb-1"
                                >
                                    <Form.Control
                                        as="select"
                                        value={status} // Use the state variable status
                                        onChange={(e) => {
                                            const selectedStatus = e.target.value;
                                            setStatus(selectedStatus);

                                            // If the status is set to "New", automatically set assignedTo to "Unassigned"
                                            if (selectedStatus === 'new') {
                                                setAssignedTo('');
                                            }
                                        }}
                                    >
                                        {choreStatusOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option.charAt(0).toUpperCase() + option.slice(1)}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        {initialChore.status === 'rejected' && (
                            <Row className="d-flex align-items-center mb-3">
                                <Col>
                                    <Form.Label>Rejection Explanation</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={rejectionExplanation}
                                        placeholder="Enter explanation for rejection"
                                        onChange={(e) => setRejectionExplanation(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        )}
                    </>
                )}
                <Row className="d-flex align-items-center">
                    {initialChore && (
                        <Col xs="auto">
                            <Button variant="outline-danger" onClick={handleDelete}>
                                <FaTrash />
                            </Button>
                        </Col>
                    )}
                    <Col>
                        <Button variant="primary" type="submit" className="w-100 d-flex align-items-center justify-content-center">
                            <MdPostAdd style={{ fontSize: '1.1rem', marginRight: '0.5rem' }} /> {initialChore ? 'Update Chore' : 'Add New Chore'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );
};

export default ChoreForm;
