import axios from 'axios';

const API_URL = '/api/draft/';

const getDraftChores = () => {
    console.log('draftService.js getDraftChores(): Fetching Draft chores...');
    return axios.get(`${API_URL}chores`);
};

const getUserChores = (userId) => {
    console.log('draftService.js getUserChores(): Fetching User chores...');
    return axios.get(`${API_URL}user-chores/${userId}`);
};

const getAllUsers = () => {
    console.log('draftService.js getAllUsers(): Fetching all users...');
    return axios.get(`${API_URL}all-users`);
};

const selectChore = (userId, choreId) => {
    console.log('draftService.js selectChore(): Fetching select chore...');
    return axios.post(`${API_URL}select`, { userId, choreId });
};

const draftService = {
    getDraftChores,
    getUserChores,
    getAllUsers,
    selectChore,
};

export default draftService;
