import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Button, Offcanvas, Container } from 'react-bootstrap';
import { FaBars, FaUserPlus } from 'react-icons/fa';

const CHNavbar = ({ title }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setShow] = React.useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleLogoClick = () => {
        localStorage.removeItem('userId');
        navigate('/login');
    };

    const handleSignupClick = () => {
        navigate('/signup', { state: { fromAdmin: true } });
    };

    return (
        <Navbar>
            <Container fluid>
                <Navbar.Brand href="" onClick={handleLogoClick}>
                    <img
                        alt=""
                        src={require('../assets/images/logo-text.png')}
                        width="90"
                        height="60"
                        className="d-inline-block align-top"
                    />{' '}
                </Navbar.Brand>
                <Navbar.Brand><h1>{title}</h1></Navbar.Brand>
                {location.pathname === '/admin' && (
                    <>
                    
                        <Button variant="outline-primary" onClick={handleShow}>
                            <FaBars />
                        </Button>
                        <Offcanvas show={show} onHide={handleClose} placement="end">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Admin Menu</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="flex-column">
                                    <Nav.Link onClick={handleSignupClick}>
                                        <FaUserPlus style={{ marginRight: '8px' }} />
                                        Register New User
                                    </Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </>
                )}
            </Container>
        </Navbar>
    );
};

export default CHNavbar;
