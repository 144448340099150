import React from 'react';
import { Alert, Row, Col, Card, Badge, Stack } from 'react-bootstrap';

const Draft = ({ chores, onSelectChore }) => {
    return (
        <div className="mt-5">
            <Stack direction="horizontal" gap={3}>
                <div className="p-2">
                    <h2>Available Chores</h2>
                </div>
                <div className="ms-auto vr" />
                <div className="p-2">
                    <h2>
                        {chores.length >= 0 && (
                            <Badge pill bg="secondary">
                                {chores.length}
                            </Badge>
                        )}
                    </h2>
                </div>
            </Stack>
            {chores.length > 0 ? (
                <Row>
                    {chores.map(chore => (
                        <Col key={chore._id} sm={12} md={6} lg={4} className="mb-3">
                            <Card 
                                className="bg-primary text-white" 
                                style={{ cursor: 'pointer' }} 
                                onClick={() => onSelectChore(chore._id)}
                            >
                                <Card.Header as="h6" className="d-flex justify-content-end">
                                    <span>{chore.status}</span>
                                </Card.Header>
                                <Card.Body className="pt-2">
                                    <Row>
                                        <Col xs={10}>
                                            <Card.Title>{chore.description}</Card.Title>
                                        </Col>
                                        <Col xs={2} className="text-center d-flex flex-column justify-content-center p-0" style={{ borderLeft: 'var(--bs-border-width) solid var(--bs-border-color-translucent)' }}>
                                            <Card.Text as="div">
                                                <span>Points</span>
                                                <br />
                                                <h4>{chore.points}</h4>
                                            </Card.Text>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-center p-0">
                                    <small className="text-white">Tap to Select</small>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
            ) : (
                <>
                    <hr className='mt-0'/>
                    <Alert variant="light">
                        No available chores remaining.
                    </Alert>
                </>
            )}
        </div>
    );
};

export default Draft;
