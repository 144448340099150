import React, { useEffect, useState } from 'react';
import rewardService from '../services/rewardService';
import choreService from '../services/choreService';
import { ProgressBar, ListGroup, OverlayTrigger, Tooltip, Row, Col, Card } from 'react-bootstrap';
import { FaMedal, FaStar, FaTrophy } from 'react-icons/fa';

const Rewards = ({ isAdmin }) => {
    const [rewards, setRewards] = useState([]);
    const [maxReward, setMaxReward] = useState(0);
    const [totalChores, setTotalChores] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [completedChores, setCompletedChores] = useState(0);
    const [completedPoints, setCompletedPoints] = useState(0);

    useEffect(() => {
        const fetchRewardsAndChores = async () => {
            try {
                const rewardsRes = await rewardService.getRewards();
                const choresRes = await choreService.getChores();
                const rewardsData = rewardsRes.data;
                const choresData = choresRes.data;

                // Calculate overall statistics
                const totalChoresCount = choresData.length;
                const totalPointsSum = choresData.reduce((total, chore) => total + chore.points, 0);
                const completedChoresCount = choresData.filter(chore => chore.status === 'completed').length;
                const completedPointsSum = choresData.filter(chore => chore.status === 'completed').reduce((total, chore) => total + chore.points, 0);

                setTotalChores(totalChoresCount);
                setTotalPoints(totalPointsSum);
                setCompletedChores(completedChoresCount);
                setCompletedPoints(completedPointsSum);

                // Calculate the total value of assigned chores for each user by matching name
                const rewardsWithTotalChores = rewardsData.map(reward => {
                    const userChores = choresData.filter(chore => 
                        chore.assignedTo && chore.assignedTo.name === reward.name
                    );
                    const totalChoresValue = userChores.reduce((total, chore) => total + chore.points, 0);
                    const completedChoresCount = userChores.filter(chore => chore.status === 'completed').length;
                    const completedChoresValue = userChores.filter(chore => chore.status === 'completed').reduce((total, chore) => total + chore.points, 0);
                    return { 
                        ...reward, 
                        totalChoresValue,
                        userChoresCount: userChores.length,
                        completedChoresCount,
                        completedChoresValue 
                    };
                });

                // Calculate the maximum reward value from the total chores value
                const maxRewardValue = calculateMaxReward(rewardsWithTotalChores);
                setRewards(rewardsWithTotalChores);
                setMaxReward(maxRewardValue);
            } catch (error) {
                console.error(error);
            }
        };

        fetchRewardsAndChores();
    }, []);

    // Function to calculate the maximum reward value
    const calculateMaxReward = (rewards) => {
        if (rewards.length === 0) return 0;
        return Math.max(...rewards.map(reward => reward.totalChoresValue));
    };

    // Sort rewards based on the ratio of reward.reward / reward.totalChoresValue
    const sortedRewards = [...rewards].sort((a, b) => (b.reward / b.totalChoresValue) - (a.reward / a.totalChoresValue));

    // Function to get an icon based on the rank
    const getRankIcon = (rank) => {
        switch (rank) {
            case 1:
                return <FaTrophy className="text-warning" />;
            case 2:
                return <FaMedal className="text-secondary" />;
            case 3:
                return <FaStar className="text-danger" />;
            default:
                return <FaStar className="text-muted" />;
        }
    };

    return (
        <div className="mt-5">
            <h2>Rewards</h2>

            <ListGroup>
                {sortedRewards.map((reward, index) => (
                    <ListGroup.Item key={index} className="d-flex align-items-center">
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>{reward.reward}</Tooltip>}
                        >
                            <div className="me-3">{getRankIcon(index + 1)}</div>
                        </OverlayTrigger>
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between">
                                <span><strong>{reward.name}</strong></span>
                                {isAdmin && (<span>Points: {reward.completedChoresValue} / {reward.totalChoresValue}</span>)}
                                <span>{reward.completedChoresCount} / {reward.userChoresCount}&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<strong>{((reward.reward / reward.totalChoresValue) * 100).toFixed(0)}%</strong></span>
                            </div>
                            <ProgressBar
                                now={((reward.reward / reward.totalChoresValue) * 100).toFixed(0)}
                                animated
                                style={{ height: '1.5rem', marginTop: '0.5rem' }}
                                variant={index === 0 ? 'success' : index === 1 ? 'info' : 'warning'}
                            />
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            

            {isAdmin && (
                <Card className="mt-4 mb-4">
                    <Card.Body>
                        <Row>
                            <Col>
                                <h4>Overall Summary</h4>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col md={3} sm={6} className="mb-3">
                                <Card className="shadow-sm">
                                    <Card.Body>
                                        <h5>Total<br></br>Chores</h5>
                                        <span className="display-6">{totalChores}</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3} sm={6} className="mb-3">
                                <Card className="shadow-sm">
                                    <Card.Body>
                                        <h5>Total<br></br>Points</h5>
                                        <span className="display-6">{totalPoints}</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3} sm={6} className="mb-3">
                                <Card className="shadow-sm">
                                    <Card.Body>
                                        <h5>Completed Chores</h5>
                                        <span className="display-6">{completedChores} </span>
                                        <span className="display-6">({((completedChores / totalChores) * 100).toFixed(0)}%)</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3} sm={6} className="mb-3">
                                <Card className="shadow-sm">
                                    <Card.Body>
                                        <h5>Completed Points</h5>
                                        <span className="display-6">{completedPoints} </span>
                                        <span className="display-6">({((completedPoints / totalPoints) * 100).toFixed(0)}%)</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </div>
    );
};

export default Rewards;
